/**
 * @prettier
 */

// React Packages
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

// Actions
import { getUUID } from '../../screens/actions/configActions';
import { getCompanyName } from '../../services/shared/helpers';
import { submissionStarted } from '../../screens/actions/submissionStateActions';

// Components
import CustomButton from '../../components/CustomButton';
import Header from '../../components/Header';
import idCheckIcon from '../../assets/img/idCheck.svg';

// Images
import logo from '../../assets/img/idpal_logo.svg';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.reCaptcha;

const LinkText = ({ linkTo = "#", title = "", ...props }) => {
    return (<a href={linkTo} target="_blank" title={title} aria-label={{title}} rel="noreferrer">
        {props.children}
    </a> )
};

const ReCaptchaPageV1 = ({
    getUUID,
    i18n,
    branding,
    submission,
    policies,
} ) => {

    const recaptchaRef = useRef(null);
    const primaryFocusRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState({
        isRecaptchaEmpty: false,
    });

    const { t } = useTranslation();

    const setRecaptchaError = () => {
        recaptchaRef.current.reset();
        setError({
            isRecaptchaEmpty: true,
        });
    };
   const handleReCaptchaSubmit = event => {
        event.preventDefault();
        const recaptchaToken = recaptchaRef.current.getValue();
        if (recaptchaToken.length) {
            setIsSubmitting(true);
            getUUID(recaptchaToken);
        } else {
            setRecaptchaError();
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (primaryFocusRef && primaryFocusRef.current) {
            primaryFocusRef.current.focus();
        }
        document.title = t('idpal_doc_title_start');
        return () => null;
    }, []);

     const renderPoweredByIDPal = () =>
        <div className={'identity-mark-section'}>
            <p className='identity-mark-text'>{t('idpal_powered_by')}</p>
            <img
                className='small-logo'
                src={logo}
                alt={'ID-Pal'}
            />
        </div>
    return (

        <div className="root-layout-container start-page">
            <Header showLanguageSelect/>
            <div className='start-logo-container'>
                <div className='icon-group-wrapper'>
                    <svg
                        alt='Start Screen Ellipse Icon'
                        className='ellipse-icon'
                        viewBox="0 0 177 177">
                        <circle cx="88.5" cy="88.5" r="88.5" fill={`#${branding.primary_color}`} fillOpacity="0.3"/>
                    </svg>
                    <img
                        src={idCheckIcon}
                        className='card-icon'
                        alt='Start Screen Document Icon'
                    />
                </div>
            </div>
            <div className='start-content-wrapper'>
                <h1
                    ref={primaryFocusRef}
                    tabIndex={0}
                    className="start-text-container">
                    <Trans
                        i18nKey='idpal_start_screen_default'
                        values={{companyName: getCompanyName(branding.name)}}
                        components={{
                            br: <br />,
                        }}
                    />
                </h1>

                <form onSubmit={handleReCaptchaSubmit}>
                    <div className='g-recaptcha-wrapper'>
                        <div className='g-recaptcha-content'>
                            <div
                                key={i18n.language}
                                className={`${
                                    error.isRecaptchaEmpty
                                        ? 'g-recaptcha-error-border'
                                        : ''
                                }`}
                            >
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={recaptchaRef}
                                    hl={i18n.language}
                                />
                            </div>
                            {error.isRecaptchaEmpty && (
                                <p className='g-recaptcha-error'>
                                    {t('idpal_recaptcha_prompt')}
                                </p>
                            )}
                        </div>
                    </div>
                    <p className="start-privacy-policy-text">
                        <Trans
                            i18nKey='idpal_terms_and_conditions_warning'
                            values={{sessionState: submission.new? t('idpal_start').toLowerCase() : t('idpal_continue').toLowerCase()}}
                            components={{
                            link1: <LinkText linkTo={policies.terms_and_conditions} title={'Terms and Conditions'}/>,
                            link2: <LinkText linkTo={policies.privacy_policy} title={'here'}/>,
                            }}
                        />
                    </p>
                    <div className="start-cta-container">
                        <CustomButton
                            className='btn'
                            label={t('idpal_start')}
                            isDisabled={isSubmitting}
                            handleClick={handleReCaptchaSubmit}
                            actionDataLabel={ACTION.continueButton}
                        />
                        {
                            !branding.whitelist &&
                                renderPoweredByIDPal()
                        }
                    </div>
                   
                </form>
            </div>             
        </div>

    )
};

function mapStateToProps(state) {
    return {
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
        submission: state.submissionState.details,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getUUID, submissionStarted }, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(ReCaptchaPageV1)
);
